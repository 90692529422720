import React from "react"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import { getFromTheme as theme } from "../../../utils/styles"
import { H3, H5 } from "../../common/typography"
import Marker from "../../markers"
import HighlightedText from "../../common/highlightedText"
import Icons from "../../icons"
import Photos from "../../images/photos"
import { AnyIcon } from "../../icons/base"
import { useIntl } from "gatsby-plugin-intl"

const ValuesWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  justify-items: flex-start;
  grid-row-gap: 40px;
  grid-column-gap: 64px;

  @media (min-width: ${theme("breakpoints.medium")}px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: ${theme("breakpoints.medium")}px) {
    grid-template-columns: 1fr;
  }
`

const PhotoWrapper = styled.div`
  grid-row: 1 / span 5;

  @media (min-width: ${theme("breakpoints.medium")}px) {
    grid-column: 1 / span 2;
  }

  @media (min-width: ${theme("breakpoints.large")}px) {
    grid-column: 1 / span 1;
    max-width: 33vw;
  }

  @media (max-width: ${theme("breakpoints.medium")}px) {
    grid-column: 1 / span 1;
  }

  img {
    overflow: hidden;
    border-radius: ${theme("borderRadius.cards")}px;
  }
`

const ValueStatement = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  ${H5} {
    text-align: left;
    display: flex;
    flex-basis: 100%;

    @media (min-width: ${theme("breakpoints.large")}px) {
      max-width: 20vw;
    }
  }
`

const Description = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 72px;

  @media (max-width: ${theme("breakpoints.medium")}px) {
    gap: 32px;
  }
`

const ValuesBlock = ({ values }: { values: IValue[] }) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <H3 fluid style={{ textAlign: "center" }}>
        <HighlightedText color="primary" textId="about.values.title" />
      </H3>

      <ValuesWrapper>
        <PhotoWrapper>
          <Photos.BraverTalk3Woman1 />
        </PhotoWrapper>
        {values.map((value) => {
          const idx = values.indexOf(value)
          return (
            <Fade up delay={200 + idx * 100}>
              <ValueStatement>
                <Marker color="primary" Icon={Icons[value.icon]} />
                <Description>
                  <H5>
                    <HighlightedText color="primary" textId={value.textKey} />
                  </H5>
                </Description>
              </ValueStatement>
            </Fade>
          )
        })}
      </ValuesWrapper>
    </Wrapper>
  )
}

export default ValuesBlock

export interface IValue {
  textKey: string
  icon: string
}
