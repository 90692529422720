import React from "react"
import ReactMarkdown from "react-markdown"
import { NormalComponents } from "react-markdown/lib/complex-types"
import { SpecialComponents } from "react-markdown/lib/ast-to-react"
import { Component } from "../types"

const Paragraphs = ({
  text,
  Paragraph,
}: {
  text: string
  Paragraph: Component
}) => {
  const markdownSubstitutions = {
    p: Paragraph,
  } as Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  >

  return <ReactMarkdown children={text} components={markdownSubstitutions} />
}

export default Paragraphs
