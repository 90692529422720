import React from "react"
import { Component } from "../../../types"
import { LayoutOrientation } from "../../../../utils/breakpoints"
import { FormattedMessage, IntlShape } from "gatsby-plugin-intl"
import { useScrollProgress } from "../../../../utils/scrollProgress"
import {
  HistoryCard,
  HistoryItemBody,
  HistoryItemDate,
  HistoryItemDot,
  HistoryItemTitle,
} from "./elements"
import { Parallax } from "react-scroll-parallax"
import { PhotoWrapper } from "../../../blocks/article"
import { ITrackedNode, ITrackedNodeArg } from "../../../../utils/scrollTracker"

export interface IHistoryItemArg extends ITrackedNodeArg<HTMLDivElement> {
  date: string
  title: string
  body: string
  Image?: Component
}

export interface IHistoryItem extends ITrackedNode<HTMLDivElement> {
  date: string
  title: string
  body: string
  Image?: Component
}

export interface HistoryItemProps {
  layout: LayoutOrientation
  intl: IntlShape
  item: IHistoryItem
  index: number
  last: boolean
  [key: string]: any
}

export function timelineSideName(
  items: IHistoryItem[],
  item: IHistoryItem
): string {
  const idx = items.indexOf(item) % 2
  if (idx == 0) {
    return "right"
  } else {
    return "left"
  }
}

const HistoryItem = ({
  intl,
  layout,
  item,
  last,
  index,
  ...props
}: HistoryItemProps) => {
  const maybePublish = (newProgress: number, exit = false) => {
    if (!exit) {
      updateProgress(newProgress)
    }
  }

  const [progress, updateProgress] = useScrollProgress({
    lowerBound: 0.2,
    upperBound: 0.5,
    active: layout == "horizontal",
  })

  return (
    <HistoryCard
      key={item.key}
      scrollProgress={progress}
      layout={layout}
      {...props}
    >
      <Parallax
        rootMargin={{ top: -120, bottom: 0, left: 0, right: 0 }}
        onProgressChange={maybePublish}
        onExit={() => maybePublish(0, true)}
        key={`card-parallax-${index}`}
      >
        <HistoryItemDot
          ref={item.node}
          key={`card-dot-${index}`}
        ></HistoryItemDot>
      </Parallax>

      {item.Image ? (
        <PhotoWrapper key={`card-${index}`}>
          <item.Image />
        </PhotoWrapper>
      ) : (
        <></>
      )}

      <HistoryItemDate key={`card-date-${index}`}>
        <FormattedMessage key={`card-date-msg-${index}`} id={item.date} />
      </HistoryItemDate>
      <HistoryItemTitle key={`card-title-${index}`}>
        <FormattedMessage key={`card-title-msg-${index}`} id={item.title} />
      </HistoryItemTitle>
      <HistoryItemBody key={`card-body-${index}`}>
        <FormattedMessage key={`card-body-msg-${index}`} id={item.body} />
      </HistoryItemBody>
    </HistoryCard>
  )
}

export default HistoryItem
