import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Component } from "../types"

export interface ITeamMemberPhoto {
  Normal: Component
  Crazy: Component
}

const TeamMembers: { [key: string]: ITeamMemberPhoto } = {
  Philippe_1: {
    Normal: () => (
      <StaticImage
        alt="Philippe Dupuis"
        src="../../images/team/philippe-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Philippe Dupuis"
        src="../../images/team/philippe-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Adam_1: {
    Normal: () => (
      <StaticImage
        alt="Adam B Bolduc"
        src="../../images/team/adam-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Adam B Bolduc"
        src="../../images/team/adam-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Eric_1: {
    Normal: () => (
      <StaticImage
        alt="Eric Belleville"
        src="../../images/team/eric-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Eric Belleville"
        src="../../images/team/eric-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Guillaume_1: {
    Normal: () => (
      <StaticImage
        alt="Eric Belleville"
        src="../../images/team/guillaume-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Eric Belleville"
        src="../../images/team/guillaume-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Francois_1: {
    Normal: () => (
      <StaticImage
        alt="François Gauthier"
        src="../../images/team/francois-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="François Gauthier"
        src="../../images/team/francois-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Joel_1: {
    Normal: () => (
      <StaticImage
        alt="Joel Trottier-Hebert"
        src="../../images/team/joel-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Joel Trottier-Hebert"
        src="../../images/team/joel-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Magalie_1: {
    Normal: () => (
      <StaticImage
        alt="Magalie Caouette"
        src="../../images/team/magalie-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Magalie Caouette"
        src="../../images/team/magalie-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  MarieLou_1: {
    Normal: () => (
      <StaticImage
        alt="Marie-Lou Gagnon"
        src="../../images/team/marie-lou-3.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Marie-Lou Gagnon"
        src="../../images/team/marie-lou-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  MarieEve_1: {
    Normal: () => (
      <StaticImage
        alt="Marie-Ève Dugas"
        src="../../images/team/marieeve-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Marie-Ève Dugas"
        src="../../images/team/marieeve-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Mathieu_1: {
    Normal: () => (
      <StaticImage
        alt="Mathieu D'Amours"
        src="../../images/team/mathieu-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Mathieu D'Amours"
        src="../../images/team/mathieu-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Michel_1: {
    Normal: () => (
      <StaticImage
        alt="Michel Bernard"
        src="../../images/team/michel-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Michel Bernard"
        src="../../images/team/michel-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
  Vincent_1: {
    Normal: () => (
      <StaticImage
        alt="Vincent Aubé"
        src="../../images/team/vincent-1.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
    Crazy: () => (
      <StaticImage
        alt="Vincent Aubé"
        src="../../images/team/vincent-1c.jpg"
        layout="constrained"
        placeholder="none"
      />
    ),
  },
}

export default TeamMembers
