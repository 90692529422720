import { FormattedMessage } from "gatsby-plugin-intl"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { fluidRange } from "polished"
import { getFromTheme as theme, styledMap } from "../../utils/styles"
import Button from "../buttons"
import { H3 as _H3, P as _P } from "../common/typography"
import {
  LayoutOrientation,
  useLayoutOrientation,
} from "../../utils/breakpoints"
import Illustrations from "../illustrations"

const H3 = styled(_H3)`
  min-width: 154px;
`

const P = styled(_P)``

const IllustrationWrapper = styled.div`
  position: absolute;
`

const IndividualsSection = styled.div<{ layout: LayoutOrientation }>`
  background-color: ${theme("colors.web.secondary.xpale")};

  ${(props) =>
    props.layout == "vertical"
      ? css`
          border-top-right-radius: ${theme("borderRadius.cards")}px;
          border-top-left-radius: ${theme("borderRadius.cards")}px;
        `
      : css`
          border-top-left-radius: ${theme("borderRadius.cards")}px;
          border-bottom-left-radius: ${theme("borderRadius.cards")}px;
        `}

  overflow: hidden;

  &.hover {
    ${IllustrationWrapper} {
      transform: scale(1.05);
    }
  }

  ${IllustrationWrapper} {
    right: min(-70px, max(-11vw, -120px));
    width: max(224px, min(32vw, 380px));
    transition: transform 0.2s;

    ${fluidRange(
      {
        prop: "bottom",
        fromSize: "-24px",
        toSize: "-35px",
      },
      "670px",
      "1080px"
    )}
  }
`

const OrganizationsSection = styled.div<{ layout: LayoutOrientation }>`
  background-color: ${theme("colors.web.accent.xpale")};

  ${(props) =>
    props.layout == "vertical"
      ? css`
          border-bottom-left-radius: ${theme("borderRadius.cards")}px;
          border-bottom-right-radius: ${theme("borderRadius.cards")}px;
        `
      : css`
          border-top-right-radius: ${theme("borderRadius.cards")}px;
          border-bottom-right-radius: ${theme("borderRadius.cards")}px;
        `}

  overflow: hidden;

  &.hover {
    ${IllustrationWrapper} {
      transform: scale(1.05);
    }
  }

  ${IllustrationWrapper} {
    right: min(-160px, max(-20vw, -330px));
    width: max(330px, min(41vw, 680px));
    transition: transform 0.2s;

    ${fluidRange(
      {
        prop: "bottom",
        fromSize: "72px",
        toSize: "-32px",
      },
      "375px",
      "1080px"
    )}
  }
`

const Wrapper = styled.div<{ layout: LayoutOrientation }>`
  display: flex;
  align-items: stretch;
  gap: 2px;
  cursor: pointer;

  flex-direction: ${styledMap("layout", {
    vertical: "column",
    horizontal: "row",
  })};

  ${H3}, ${P} {
    position: relative;
    margin-bottom: 30px;
    z-index: 2;
  }

  ${IndividualsSection}, ${OrganizationsSection} {
    position: relative;
    flex-grow: 1;

    ${(props) =>
      fluidRange(
        [
          {
            prop: "padding-left",
            fromSize: "32px",
            toSize: "84px",
          },
          props.layout == "vertical"
            ? {
                prop: "padding-right",
                fromSize: "108px",
                toSize: "144px",
              }
            : {
                prop: "padding-right",
                fromSize: "54px",
                toSize: "256px",
              },
          {
            prop: "padding-top",
            fromSize: "52px",
            toSize: "72px",
          },
          props.layout == "vertical"
            ? {
                prop: "padding-bottom",
                fromSize: "52px",
                toSize: "72px",
              }
            : {
                prop: "padding-bottom",
                fromSize: "84px",
                toSize: "118px",
              },
        ],
        "320px",
        "1400px"
      )};
  }
`

interface SolutionsMessages {
  professionals: {
    title: string
    description: string
    button: string
  }
  organizations: {
    title: string
    description: string
    button: string
  }
}

const defaultMessages: SolutionsMessages = {
  professionals: {
    title: "solutionsBlock.individuals.title",
    description: "solutionsBlock.individuals.description",
    button: "solutionsBlock.individuals.button",
  },
  organizations: {
    title: "solutionsBlock.organizations.title",
    description: "solutionsBlock.organizations.description",
    button: "solutionsBlock.organizations.button",
  },
}

const SolutionsBlock = ({
  selectIndividuals,
  selectOrganizations,
  messages = defaultMessages,
}: {
  selectIndividuals: () => void
  selectOrganizations: () => void
  messages?: SolutionsMessages
  [key: string]: any
}) => {
  const [isIndividualHovered, setIndividualHovered] = useState(false)
  const [isOrganizationHovered, setOrganizationHovered] = useState(false)
  const layout = useLayoutOrientation()

  return (
    <Wrapper layout={layout}>
      <IndividualsSection
        onMouseEnter={() => setIndividualHovered(true)}
        onMouseLeave={() => setIndividualHovered(false)}
        onClick={selectIndividuals}
        layout={layout}
        className={`${isIndividualHovered ? "hover" : ""}`}
      >
        <H3 fluid>
          <FormattedMessage id={messages.professionals.title} />
        </H3>

        <P small>
          <FormattedMessage id={messages.professionals.description} />
        </P>

        <Button.Underline
          buttonStyle="navigate"
          color="secondary"
          hovered={isIndividualHovered}
          onClick={selectIndividuals}
          intlKey={messages.professionals.button}
        />

        <IllustrationWrapper>
          {Illustrations.Walking_3.FullWidth ? (
            <Illustrations.Walking_3.FullWidth />
          ) : (
            <></>
          )}
        </IllustrationWrapper>
      </IndividualsSection>

      <OrganizationsSection
        onMouseEnter={() => setOrganizationHovered(true)}
        onMouseLeave={() => setOrganizationHovered(false)}
        onClick={selectOrganizations}
        layout={layout}
        className={`${isOrganizationHovered ? "hover" : ""}`}
      >
        <H3 fluid>
          <FormattedMessage id={messages.organizations.title} />
        </H3>

        <P small>
          <FormattedMessage id={messages.organizations.description} />
        </P>

        <Button.Underline
          buttonStyle="navigate"
          color="accent"
          hovered={isOrganizationHovered}
          onClick={selectOrganizations}
          intlKey={messages.organizations.button}
        />

        <IllustrationWrapper>
          {Illustrations.Org.FullWidth ? (
            <Illustrations.Org.FullWidth />
          ) : (
            <></>
          )}
        </IllustrationWrapper>
      </OrganizationsSection>
    </Wrapper>
  )
}

export default SolutionsBlock
