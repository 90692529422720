import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Forms from "../../../utils/typeform"
import PresenterBlock from "../../blocks/presenter"
import Button from "../../buttons"
import Photos from "../../images/photos"
import TeamMembers from "../../images/team"
import Illustrations from "../../illustrations"
import Icons from "../../icons"

const CollaborationBlock = () => {
  const intl = useIntl()

  return (
    <PresenterBlock
      color="accent"
      Photo={Photos.ProfessionalWoman1}
      title={intl.formatMessage({ id: "blocks.collaboration.title" })}
      subtitle={intl.formatMessage({
        id: "blocks.collaboration.description",
      })}
      superTitle={intl.formatMessage({
        id: "blocks.collaboration.superTitle",
      })}
      button={
        <Button.Underline
          color="accent"
          buttonStyle="icon"
          Icon={Icons.Letter.Medium}
          text={intl.formatMessage({
            id: "blocks.collaboration.callToAction",
          })}
          onClick={() => Forms.contact(intl)}
        />
      }
    />
  )
}

export default CollaborationBlock
