import React from "react"
import styled from "styled-components"
import Illustrations from "../../illustrations"
import TeamMember, {
  ITeamMember,
  Wrapper as TeamMemberWrapper,
} from "./teamMember"
import { getFromTheme as theme } from "../../../utils/styles"

const Founder = styled(TeamMember)``

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;

  ${TeamMemberWrapper} {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 50%;

    &:first-child {
      margin-top: 120px;
    }
  }

  @media screen and (max-width: ${theme("breakpoints.small")}px) {
    flex-direction: column;
    gap: 32px;
    justify-content: flex-start;
    align-items: center;
  }
`

const Founders = ({ founders }: { founders: ITeamMember[] }) => {
  const [first, second] = founders
  return (
    <Wrapper>
      <Founder
        member={first}
        cardSize="large"
        color="secondary"
        DecoratingIllustration={Illustrations.DecoratingBulb}
      />

      <Founder member={second} cardSize="large" color="accent" />
    </Wrapper>
  )
}

export default Founders
