import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import styled from "styled-components"
import theme from "../../../utils/theme"
import Button from "../../buttons"
import HighlightedText from "../../common/highlightedText"
import { H3, P } from "../../common/typography"
import TeamMember, { ITeamMember } from "./teamMember"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  --offset: 48px;

  & > div > div,
  & > div > div > div {
    width: auto !important;
  }

  & > div > div > div {
    &:nth-child(2) > :first-child {
      margin-top: calc(1 * var(--offset));
    }
    &:nth-child(3) > :first-child {
      margin-top: calc(2 * var(--offset));
    }
    &:nth-child(4) > :first-child {
      margin-top: calc(3 * var(--offset));
    }
  }
`

const Captions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 475px;
  text-align: center;
  margin-bottom: 24px;
`

const Team = ({ members }: { members: ITeamMember[] }) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <Captions>
        <H3>
          <HighlightedText textId="about.team.title" color="accent" />
        </H3>
        <P>
          <FormattedMessage id="about.team.description" />
        </P>
        <Button
          color="accent"
          text={intl.formatMessage({ id: "about.team.linkedInCallToAction" })}
          href="https://www.linkedin.com/company/braverhealth/"
          target="_blank"
        />
      </Captions>

      <ResponsiveMasonry
        columnsCountBreakPoints={{
          [theme.breakpoints.xsmall]: 1,
          [theme.breakpoints.small]: 2,
          [theme.breakpoints.large]: 3,
          [theme.breakpoints.xlarge]: 4,
        }}
      >
        <Masonry gutter="32px">
          {members.map((member) => (
            <TeamMember
              key={members.indexOf(member)}
              member={member}
              cardSize="small"
              color="primary"
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Wrapper>
  )
}

export default Team
