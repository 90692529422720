import React from "react"
import { IntlProvider, navigate, useIntl } from "gatsby-plugin-intl"
import MissionHero from "../components/pages/about/hero"
import ConvictionBlock from "../components/pages/about/conviction"
import Layout from "../components/site/layout"
import Seo from "../components/common/seo"
import SignUp from "../components/blocks/signUp"
import HistoryBlock from "../components/pages/about/history"
import { PageContent, Section } from "../components/common/layout"
import { IHistoryItemArg } from "../components/pages/about/history/item"
import { ITeamMember } from "../components/pages/about/teamMember"
import TeamMembers from "../components/images/team"
import styled from "styled-components"
import Founders from "../components/pages/about/founders"
import Team from "../components/pages/about/team"
import SolutionsBlock from "../components/blocks/solutions"
import ValuesBlock, { IValue } from "../components/pages/about/values"
import { getLocale, getPathName, messagesForLanguage } from "../utils/localized"
import CollaborationBlock from "../components/pages/blocks/collaboration"

const TeamSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AboutContent = () => {
  const intl = useIntl()

  return (
    <PageContent>
      <Section t-xlg h-m-flush>
        <MissionHero />
      </Section>

      <Section h-centered t-xlg>
        <ConvictionBlock />
      </Section>

      <Section h-centered t-xlg>
        <ValuesBlock values={values} />
      </Section>

      <Section t-xlg h-m-sm>
        <SolutionsBlock
          selectIndividuals={() => navigate("/solutions/professionals")}
          selectOrganizations={() => navigate("/solutions/organizations")}
        />
      </Section>

      <Section h-centered>
        <HistoryBlock items={historyItems} />
      </Section>

      <Section t-lg>
        <Founders founders={[MarieLou, Mathieu]} />
      </Section>

      <TeamSection t-lg>
        <Team members={teamMembers} />
      </TeamSection>

      <Section t-lg h-centered>
        <CollaborationBlock />
      </Section>

      <Section t-lg h-centered h-m-sm>
        <SignUp />
      </Section>
    </PageContent>
  )
}

const AboutPage = () => (
  <Layout>
    <AboutContent />
  </Layout>
)

export const Head = ({ location }) => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

const values: IValue[] = [
  {
    textKey: "about.values.collaboration",
    icon: "Persons",
  },
  {
    textKey: "about.values.innovation",
    icon: "Audace",
  },
  {
    textKey: "about.values.authenticity",
    icon: "Partage",
  },
  {
    textKey: "about.values.question",
    icon: "Courage",
  },
  {
    textKey: "about.values.listen",
    icon: "Ecoute_2",
  },
]

const MarieLou: ITeamMember = {
  fullName: "Marie-Lou Gagnon",
  titleIntlKey: "team.marielou.title",
  descriptionKey: "team.marielou.description",
  Photo: TeamMembers.MarieLou_1,
}
const Mathieu: ITeamMember = {
  fullName: "Mathieu D'Amours",
  titleIntlKey: "team.mathieu.title",
  descriptionKey: "team.mathieu.description",
  Photo: TeamMembers.Mathieu_1,
}

const teamMembers: ITeamMember[] = [
  {
    fullName: "Adam B Bolduc",
    titleIntlKey: "team.titles.seniorSoftwareEngineer",
    descriptionKey: "team.adam.description",
    Photo: TeamMembers.Adam_1,
  },
  {
    fullName: "Philippe Dupuis",
    titleIntlKey: "team.titles.softwareEngineer",
    descriptionKey: "team.philippe.description",
    Photo: TeamMembers.Philippe_1,
  },
  {
    fullName: "Eric Belleville",
    titleIntlKey: "team.titles.medicalAdvisor",
    descriptionKey: "team.eric.description",
    Photo: TeamMembers.Eric_1,
  },
  {
    fullName: "Joël Trottier-Hebert",
    titleIntlKey: "team.titles.seniorSoftwareEngineer",
    descriptionKey: "team.joel.description",
    Photo: TeamMembers.Joel_1,
  },
  {
    fullName: "Magalie Caouette",
    titleIntlKey: "team.titles.softwareEngineer.female",
    descriptionKey: "team.magalie.description",
    Photo: TeamMembers.Magalie_1,
  },
  {
    fullName: "Marie-Ève Dugas",
    titleIntlKey: "team.titles.projectManager",
    descriptionKey: "team.marieeve.description",
    Photo: TeamMembers.MarieEve_1,
  },
  {
    fullName: "Michel Bernard",
    titleIntlKey: "team.titles.strategicAdvisor",
    descriptionKey: "team.michel.description",
    Photo: TeamMembers.Michel_1,
  },
  {
    fullName: "Vincent Aubé",
    titleIntlKey: "team.titles.softwareEngineer",
    descriptionKey: "team.vincent.description",
    Photo: TeamMembers.Vincent_1,
  },
  {
    fullName: "Guillaume Jean",
    titleIntlKey: "team.titles.softwareEngineer",
    descriptionKey: "team.guillaume.description",
    Photo: TeamMembers.Guillaume_1,
  },
]

const generateHistoryItem = (index: number): IHistoryItemArg => ({
  key: `${index}`,
  date: `history.${index}.date`,
  title: `history.${index}.title`,
  body: `history.${index}.body`,
})

const historyItems: IHistoryItemArg[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
].map(generateHistoryItem)

export default AboutPage
