import { useState } from "react"

export type UseScrollProgressShape = [number, (_: number) => void]

export interface ScrollProgressProps {
  scrollProgress: number
}

export const useScrollProgress = ({
  lowerBound = 1.0,
  upperBound = 0.0,
  active = true,
  onChange = (_) => {},
}: {
  lowerBound?: number
  upperBound?: number
  active?: boolean
  onChange?: (_: number) => void
}): UseScrollProgressShape => {
  const [progress, setProgress] = useState(0.0)

  return [
    progress,
    (progress: number) => {
      if (!active) {
        onChange(1)
        setProgress(1)
        return
      }

      const range = upperBound - lowerBound

      if (progress < lowerBound) {
        onChange(0)
        setProgress(0.0)
        return
      }

      if (progress > upperBound) {
        onChange(1.0)
        setProgress(1.0)
        return
      }

      progress = Math.round(((progress - lowerBound) / range) * 10) / 10
      onChange(progress)
      setProgress(progress)
    },
  ]
}

export const scaleWithProgress =
  ({
    scaled,
    baseline = 0,
    units = "px",
    inverted = false,
  }: {
    scaled: number | number[]
    inverted?: boolean
    baseline?: number
    units?: string
  }) =>
  (props: ScrollProgressProps) => {
    const factor = inverted ? 1 - props.scrollProgress : props.scrollProgress
    if (typeof scaled == "number") {
      return `${baseline + factor * scaled}${units}`
    } else {
      return scaled.map((b) => `${baseline + factor * b}${units}`).join(" ")
    }
  }
