import React from "react"
import styled, { css } from "styled-components"
import { fluidRange } from "polished"
import { H3, P } from "../common/typography"
import styledMap from "styled-map"
import { getFromTheme as theme } from "../../utils/styles"
import Illustrations from "../illustrations"
import {
  LayoutOrientation,
  useLayoutOrientation,
} from "../../utils/breakpoints"
import { ThemeColor } from "../../utils/colors"
import { Photo } from "../images/photos"
import { Component } from "../types"
import IllustationWrapper from "../illustrations/wrapper"
import { DecoratingComponent } from "../illustrations/decorations/base"
import HighlightedText from "../common/highlightedText"

type ImageAspect = "portrait" | "landscape"

const SupTitle = styled(P)``

const PresenterTitle = styled(H3)``
const PresenterParagraph = styled(P)``

const Captions = styled.div<{ color: ThemeColor; layout: LayoutOrientation }>`
  flex-basis: 100%;
  flex-grow: 3;

  ${(props) =>
    fluidRange(
      [
        {
          prop: "padding-left",
          fromSize: "32px",
          toSize: "84px",
        },
        props.layout == "vertical"
          ? {
              prop: "padding-right",
              fromSize: "32px",
              toSize: "108px",
            }
          : {
              prop: "padding-right",
              fromSize: "16px",
              toSize: "32px",
            },
        {
          prop: "padding-top",
          fromSize: "52px",
          toSize: "72px",
        },
        props.layout == "vertical"
          ? {
              prop: "padding-bottom",
              fromSize: "32px",
              toSize: "52px",
            }
          : {
              prop: "padding-bottom",
              fromSize: "52px",
              toSize: "72px",
            },
      ],
      "860px",
      "1400px"
    )};

  ${SupTitle} {
    margin-bottom: 17px;
  }

  ${PresenterTitle} {
    margin-bottom: 30px;
  }

  ${PresenterParagraph} {
    margin-bottom: 30px;
  }

  ${PresenterTitle}, ${PresenterParagraph} {
    ${fluidRange(
      [
        {
          prop: "padding-right",
          fromSize: "32px",
          toSize: "48px",
        },
      ],
      "860px",
      "1400px"
    )};
  }
`

const Accessory = styled.div`
  overflow: hidden;
  border-radius: ${theme("borderRadius.images")}px
    ${theme("borderRadius.images")}px 0 0;
  height: 150%;

  img {
    border-radius: ${theme("borderRadius.cards")}px
      ${theme("borderRadius.cards")}px 0 0;
  }
`

const AccessoryIllustration = styled.div<{
  transform: string | undefined
  layout: LayoutOrientation
}>`
  display: flex;
  position: relative;
  overflow: visible;
  flex-direction: column;

  align-self: ${styledMap("layout", {
    horizontal: "center",
    vertical: "center",
  })};

  justify-items: ${styledMap("layout", {
    horizontal: "flex-start",
    vertical: "flex-start",
  })};

  flex-basis: ${styledMap("layout", {
    horizontal: "100%",
    vertical: "auto",
  })};

  ${IllustationWrapper.Wrapper} {
    position: ${styledMap("layout", {
      horizontal: "relative",
      vertical: "relative",
    })};

    max-height: ${styledMap("layout", {
      horizontal: "auto",
      vertical: "60vw",
    })};

    height: ${styledMap("layout", {
      horizontal: "auto",
      vertical: "60vw",
    })};

    ${(props) =>
      props.layout == "vertical"
        ? css`
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          `
        : ""}

    transform: ${(props) => props.transform ?? "none"};
  }
`

const Decorated = styled.div<{
  layout: LayoutOrientation
  imageAspect: ImageAspect
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  flex-basis: ${styledMap("imageAspect", {
    landscape: "100%",
    portrait: "60%",
  })};

  margin-right: 40px;

  margin-left: ${styledMap("layout", {
    vertical: "40px",
    horizontal: "0",
  })};

  margin-top: ${styledMap("layout", {
    vertical: "0",
    horizontal: "-40px",
  })};

  .gatsby-image-wrapper-constrained {
    height: 100%;
  }

  ${Accessory} {
    align-self: stretch;
  }

  ${Illustrations.Float} {
    align-self: flex-start;

    &.topRight {
      align-self: flex-end;
    }

    &.topLeft {
      height: 40px;
    }
  }
`

const Background = styled.div<{
  layout: LayoutOrientation
  color: ThemeColor | "transparent"
}>`
  display: flex;
  height: 100%;
  max-width: 960px;
  position: relative;

  justify-items: ${styledMap("layout", {
    default: "space-between",
    vertical: "flex-start",
  })};
  flex-direction: ${styledMap("layout", {
    default: "row",
    vertical: "column",
  })};
  align-items: ${styledMap("layout", {
    default: "stretch",
    vertical: "stretch",
  })};
  border-radius: ${theme("borderRadius.cards")}px;

  background-color: ${(props) =>
    props.color == "transparent"
      ? "transparent"
      : theme(`colors.web.${props.color}.xpale`)(props)};
`

interface PresenterBlockProps {
  color: ThemeColor
  superTitle?: string
  title: string
  subtitle: string
  Photo?: Photo
  imageAspect?: ImageAspect
  illustration?: JSX.Element
  transparent?: boolean
  illustrationTransforms?: {
    horizontal?: string
    vertical?: string
  }
  DecoratingIllustration?: Component
  decorationPosition?: "bottom" | "topCorner"
  button: JSX.Element
}

const PresenterBlock = ({
  color,
  superTitle,
  title,
  subtitle,
  button,
  Photo,
  illustration,
  illustrationTransforms,
  DecoratingIllustration,
  decorationPosition,
  transparent = false,
}: PresenterBlockProps) => {
  const layout = useLayoutOrientation({
    threshold: Photo?.orientation == "portrait" ? "small" : "medium",
  })

  return (
    <Background layout={layout} color={transparent ? "transparent" : color}>
      <Captions layout={layout} color={color}>
        {superTitle && superTitle.length > 0 ? (
          <SupTitle small>{superTitle}</SupTitle>
        ) : (
          <></>
        )}
        <PresenterTitle fluid>
          <HighlightedText text={title} color={color} />
        </PresenterTitle>
        <PresenterParagraph>{subtitle}</PresenterParagraph>
        {button}
      </Captions>

      {Photo ? (
        <Decorated
          layout={layout}
          imageAspect={Photo.orientation ?? "landscape"}
        >
          {DecoratingIllustration && decorationPosition == "topCorner" ? (
            (function () {
              let _DecoratingIllustration =
                DecoratingIllustration as DecoratingComponent
              return (
                <Illustrations.Float
                  className={_DecoratingIllustration.corner}
                  vertical="upwards"
                  verticalOffset={_DecoratingIllustration.verticalOffset}
                  horizontal={
                    _DecoratingIllustration.corner == "topLeft"
                      ? "leftwards"
                      : "rightwards"
                  }
                  horizontalOffset={_DecoratingIllustration.horizontalOffset}
                >
                  <DecoratingIllustration />
                </Illustrations.Float>
              )
            })()
          ) : (
            <></>
          )}

          <Accessory>
            <Photo />
          </Accessory>

          {DecoratingIllustration && decorationPosition == "bottom" ? (
            <Illustrations.Float vertical="centered" horizontal="leftwards">
              <DecoratingIllustration />
            </Illustrations.Float>
          ) : (
            <></>
          )}
        </Decorated>
      ) : illustration ? (
        <AccessoryIllustration
          layout={layout}
          transform={
            illustrationTransforms != null
              ? illustrationTransforms[layout]
              : undefined
          }
        >
          {illustration}
        </AccessoryIllustration>
      ) : (
        <></>
      )}
    </Background>
  )
}

export default PresenterBlock
