import React, { useState } from "react"
import styled from "styled-components"
import { fluidRange, rgba } from "polished"
import { Photo as _Photo } from "../../images/photos"
import { H3, H4, P, PSmall } from "../../common/typography"
import { getFromTheme as theme, styledMap } from "../../../utils/styles"
import Button from "../../buttons"
import { themeBreakpoint } from "../../../utils/breakpoints"
import { ThemeColor } from "../../../utils/colors"
import { ITeamMemberPhoto } from "../../images/team"
import Paragraphs from "../../common/paragraphs"
import { FormattedMessage, IntlShape, useIntl } from "gatsby-plugin-intl"
import Illustrations from "../../illustrations"
import { DecoratingComponent } from "../../illustrations/decorations/base"

const TruncatingP = styled(P)``

export const PhotoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  max-height: 400px;

  border-radius: ${theme("borderRadius.cards")}px
    ${theme("borderRadius.cards")}px 0 0;

  img {
    border-radius: ${theme("borderRadius.cards")}px
      ${theme("borderRadius.cards")}px 0 0;
  }
`

const PhotoVariant = styled.div`
  &.state-hovered {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const DateCaption = styled(P)``

const Captions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${(props) =>
    fluidRange(
      [
        {
          prop: "padding-left",
          fromSize: "32px",
          toSize: "40px",
        },
        {
          prop: "padding-right",
          fromSize: "32px",
          toSize: "40px",
        },
        {
          prop: "padding-top",
          fromSize: "24px",
          toSize: "32px",
        },
        {
          prop: "padding-bottom",
          fromSize: "32px",
          toSize: "40px",
        },
      ],
      themeBreakpoint(props, "xsmall"),
      themeBreakpoint(props, "medium")
    )};

  border-radius: 0 0 ${theme("borderRadius.cards")}px
    ${theme("borderRadius.cards")}px;

  ${DateCaption} {
    align-self: flex-start;
  }

  > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ExpandButton = styled.div``

type TeamMemberSize = "large" | "small"

export const Wrapper = styled.div<{ size: TeamMemberSize; color: ThemeColor }>`
  max-width: ${styledMap("size", {
    small: "300px",
    large: "420px",
  })};

  ${Captions} {
    background-color: ${(props) => theme(`colors.web.${props.color}.xpale`)};
  }
  transition: transform 0.2s;

  .state-hovered {
    transition: opacity 0.1s;
    opacity: 0.01;
  }

  ${TruncatingP} {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p:not(:first-child) {
      display: none;
    }
  }

  &.hovered {
    .state-hovered {
      opacity: 1;
    }
  }

  &.expanded {
    ${TruncatingP} {
      display: block;
      -webkit-line-clamp: none;

      ${PSmall} {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      p:not(:first-child) {
        display: block;
      }
    }

    ${ExpandButton} {
      display: none;
    }
  }
`

const PhotoPlaceholder = styled.div`
  height: 400px;
  border-radius: ${theme("borderRadius.cards")}px
    ${theme("borderRadius.cards")}px 0 0;

  background: linear-gradient(
    to bottom right,
    ${(props) => rgba(theme("colors.web.black.xpale")(props), 0.8)},
    ${(props) => rgba(theme("colors.web.black.xpale")(props), 0.3)}
  );

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px;

  ${H4} {
    color: ${(props) => rgba(theme("colors.web.black.initial")(props), 0.6)};
  }
`

export interface ITeamMember {
  fullName: string
  titleIntlKey: string
  descriptionKey: string
  Photo?: ITeamMemberPhoto
}

interface TeamMemberProps {
  member: ITeamMember
  cardSize: TeamMemberSize
  color: ThemeColor
  DecoratingIllustration?: DecoratingComponent
}

const TeamMember = ({
  member,
  cardSize,
  color,
  DecoratingIllustration,
}: TeamMemberProps) => {
  const intl = useIntl()
  const [isHovered, setHovered] = useState(false)
  const [isExpanded, setExpanded] = useState(false)

  return (
    <Wrapper
      className={`${isHovered ? "hovered" : ""} ${
        isExpanded ? "expanded" : ""
      }`}
      size={cardSize}
      color={color}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {DecoratingIllustration ? (
        (function () {
          let _DecoratingIllustration =
            DecoratingIllustration as DecoratingComponent
          return (
            <Illustrations.Float
              className={_DecoratingIllustration.corner}
              vertical="upwards"
              verticalOffset={_DecoratingIllustration.verticalOffset}
              horizontal={
                _DecoratingIllustration.corner == "topLeft"
                  ? "leftwards"
                  : "rightwards"
              }
              horizontalOffset={_DecoratingIllustration.horizontalOffset}
            >
              <DecoratingIllustration />
            </Illustrations.Float>
          )
        })()
      ) : (
        <></>
      )}

      <PhotoWrapper>
        {member.Photo ? (
          <>
            <PhotoVariant className="state-normal">
              <member.Photo.Normal />
            </PhotoVariant>
            {/* <PhotoVariant className="state-hovered">
              <member.Photo.Crazy />
            </PhotoVariant> */}
          </>
        ) : (
          <PhotoPlaceholder>
            <H4>
              <FormattedMessage id="team.photoPending" />
            </H4>
          </PhotoPlaceholder>
        )}
      </PhotoWrapper>

      <Captions>
        <DateCaption xsmall>
          <FormattedMessage id={member.titleIntlKey} />
        </DateCaption>

        <H4>{member.fullName}</H4>

        <TruncatingP small>
          <Paragraphs
            Paragraph={PSmall}
            text={intl.formatMessage({ id: member.descriptionKey })}
          />
        </TruncatingP>

        <ExpandButton>
          <Button.Underline
            color={color}
            text={intl.formatMessage({ id: "about.team.readMore" })}
            buttonStyle="dropdown"
            onClick={() => setExpanded(true)}
          />
        </ExpandButton>
      </Captions>
    </Wrapper>
  )
}

export default TeamMember
