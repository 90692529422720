import { fluidRange, lighten } from "polished"
import styled, { css } from "styled-components"
import { getFromTheme as theme } from "../../../../utils/styles"
import {
  LayoutOrientationProps,
  themeBreakpoint,
} from "../../../../utils/breakpoints"
import {
  scaleWithProgress,
  ScrollProgressProps,
} from "../../../../utils/scrollProgress"
import { H3, H4, P } from "../../../common/typography"

export const Heading = styled(H3)`
  text-align: center;
`

export const Body = styled(P)`
  text-align: center;
  ${(props) =>
    fluidRange(
      [{ prop: "margin-bottom", fromSize: "32px", toSize: "70px" }],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )}
`

export const HistoryItemDot = styled.div``

export const HistoryItemTitle = styled(H4)``

export const HistoryItemDate = styled(P)``

export const HistoryItemBody = styled(P)``

export const Spacer = styled.div``

type HistoryCardProps = ScrollProgressProps & LayoutOrientationProps

export const HistoryCard = styled.div<HistoryCardProps>`
  border-radius: ${theme("borderRadius.cards")}px;
  background-color: ${theme("colors.web.primary.xpale")};

  ${HistoryItemDate}, ${HistoryItemTitle} {
    margin-bottom: 20px;
  }

  ${(props) =>
    props.layout == "horizontal"
      ? css`
          --amount: ${scaleWithProgress({
        scaled: 10,
        units: "px",
        inverted: true,
      })};
          --angle: ${scaleWithProgress({
        scaled: -5,
        inverted: true,
        units: "deg",
      })};
          opacity: ${scaleWithProgress({ scaled: 1, units: "" })};
          transform: translateX(calc(var(--translation) * var(--amount)))
            perspective(400px) rotateY(calc(var(--translation) * var(--angle)));
        `
      : ""}
  transition: transform 0.1s;
`

export const TimelineCursor = styled.div``

export const InteractiveTimeline = css`
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  position: relative;
  pointer-events: none;
  --translation: 1;

  &::after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 0.5px);
    width: 1px;
    content: " ";
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100px,
      rgba(0, 0, 0, 0.1) 95%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  ${Spacer} {
    grid-column-start: 2;
    grid-column-end: span 2;
  }

  ${TimelineCursor} {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(calc(-50% + 7px)) translateY(-50%) scale(0.8);
    background-color: white;
    z-index: 1;
    opacity: 0;
    transition: all 0.15s;

    &.visible {
      opacity: 1;
      transform: translateX(calc(-50% + 7px)) translateY(-50%) scale(1);
    }
  }

  ${HistoryCard} {
    width: calc(120px + 22vw);
    max-width: 480px;
    display: inline-block;
    position: relative;
    margin-top: -32px;

    ${HistoryItemDot} {
      display: block;
      position: absolute;
      box-sizing: content-box;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: ${(props) =>
    lighten(0.15)(theme("colors.web.primary.initial")(props))};
      top: 42px;
      border: 12px solid white;
      content: " ";
    }

    &:first-child {
      margin-top: 0;
    }

    &.side-left {
      grid-column-start: 1;
      --translation: -1;

      ${HistoryItemDot} {
        transform: translateX(20px);
        ${(props) =>
    fluidRange(
      [{ prop: "right", fromSize: "-32px", toSize: "-42px" }],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )};
      }
    }

    &.side-right {
      grid-column-start: 3;

      ${HistoryItemDot} {
        transform: translateX(-20px);
        ${(props) =>
    fluidRange(
      [{ prop: "left", fromSize: "-32px", toSize: "-42px" }],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )};
      }
    }

    ${(props) =>
    fluidRange(
      [
        { prop: "padding-left", fromSize: "32px", toSize: "42px" },
        { prop: "padding-right", fromSize: "32px", toSize: "42px" },
        { prop: "padding-top", fromSize: "32px", toSize: "42px" },
        { prop: "padding-bottom", fromSize: "32px", toSize: "42px" },
      ],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )};
  }

  &.one-sided {
    grid-template-columns: 20vw 80px 2fr;

    &::after {
      left: calc(50% - 30vw);
    }

    ${TimelineCursor} {
      left: 50%;
      transform: translateX(calc(-30vw - 50%)) translateY(-50%) scale(0.8);

      &.visible {
        transform: translateX(calc(-30vw - 50%)) translateY(-50%) scale(1);
      }
    }

    ${Spacer} {
      grid-column-start: 1;
      grid-column-end: span 1;
      width: 40vw;
    }

    ${HistoryCard} {
      width: calc(60px + 50vw);
      margin-top: 20px;

      &.side-right,
      &.side-left {
        grid-column-start: 2;
        grid-column-end: span 2;
        --translation: 1;

        ${HistoryItemDot} {
          transform: translateX(-20px);

          ${(props) =>
    fluidRange(
      [{ prop: "left", fromSize: "-32px", toSize: "-42px" }],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )};
        }
      }
    }
  }
`

export const ListedTimeline = css`
  ${HistoryCard} {
    padding: 32px;
    margin-bottom: 20px;
  }

  ${TimelineCursor} {
    display: none;
  }
`

export const Timeline = styled.div<LayoutOrientationProps>`
  ${(props) =>
    props.layout == "horizontal" ? InteractiveTimeline : ListedTimeline}
`

export const Wrapper = styled.div<LayoutOrientationProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-right: 0;

  ${Heading} {
    ${(props) =>
    fluidRange(
      [{ prop: "margin-bottom", fromSize: "18px", toSize: "42px" }],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )}
  }

  ${Body}, ${Heading} {
    ${(props) =>
    props.layout == "horizontal"
      ? css`
            max-width: calc(32vw + 108px);
          `
      : css``}
  }
`
