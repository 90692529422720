import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  useIntl,
} from "gatsby-plugin-intl"
import React from "react"
import styled, { css } from "styled-components"
import { fluidRange } from "polished"
import {
  LayoutOrientationProps,
  themeBreakpoint,
  useLayoutOrientation,
} from "../../../utils/breakpoints"
import { H3, P } from "../../common/typography"
import HighlightedText from "../../common/highlightedText"
import Paragraphs from "../../common/paragraphs"
import { getFromTheme as theme } from "../../../utils/styles"
import Illustrations from "../../illustrations"
import {
  scaleWithProgress,
  ScrollProgressProps,
  useScrollProgress,
} from "../../../utils/scrollProgress"
import { Parallax } from "react-scroll-parallax"

const Heading = styled(H3)``

const Body = styled(P)``

const SupTitle = styled(P)`
  margin-bottom: 18px;
`

const Captions = styled.div`
  ${Heading} {
    ${(props) =>
      fluidRange(
        [
          {
            prop: "margin-bottom",
            fromSize: "18px",
            toSize: "42px",
          },
        ],
        themeBreakpoint(props, "medium"),
        themeBreakpoint(props, "xlarge")
      )}
  }

  ${Body} + ${Body} {
    margin-top: ${theme("font.paragraph.lineHeight.p2")};
  }
`

const Wrapper = styled.div<LayoutOrientationProps & ScrollProgressProps>`
  display: flex;
  align-items: center;
  background-color: ${theme("colors.web.secondary.xpale")};
  border-radius: ${theme("borderRadius.cards")}px;

  @media (min-width: ${(props) => themeBreakpoint(props, "medium")}) {
    will-change: transform opacity margin;
    transition: margin-top 0.2s, margin-bottom 0.2s, margin-left 0.2s,
      margin-right 0.2s, padding-top 0.2s, padding-bottom 0.2s,
      padding-left 0.2s, padding-right 0.2s, transform 0.2s, opacity 0.2s;
    margin: ${scaleWithProgress({
      scaled: [36, 36, 36, 64],
      inverted: true,
    })};
    --angle: ${scaleWithProgress({
      scaled: 10,
      inverted: true,
      units: "deg",
    })};
    opacity: ${scaleWithProgress({ scaled: 1, baseline: 0, units: "" })};
    transform: perspective(400px) rotateX(var(--angle));
  }

  ${(props) =>
    fluidRange(
      [
        {
          prop: "padding-bottom",
          fromSize: scaleWithProgress({ scaled: 36 })(props),
          toSize: scaleWithProgress({ scaled: 36, baseline: 28 })(props),
        },
        {
          prop: "padding-top",
          fromSize: scaleWithProgress({ scaled: 36 })(props),
          toSize: scaleWithProgress({ scaled: 36, baseline: 28 })(props),
        },
        {
          prop: "padding-right",
          fromSize: scaleWithProgress({ scaled: 36, baseline: 18 })(props),
          toSize: scaleWithProgress({ scaled: 36, baseline: 112 })(props),
        },
      ],
      themeBreakpoint(props, "medium"),
      themeBreakpoint(props, "xlarge")
    )}

  padding-left: 32px;
  @media (min-width: ${(props) => themeBreakpoint(props, "medium")}) {
    ${(props) =>
      fluidRange(
        [
          {
            prop: "padding-left",
            fromSize: scaleWithProgress({ scaled: 64, baseline: 56 })(props),
            toSize: scaleWithProgress({ scaled: 64, baseline: 300 })(props),
          },
        ],
        themeBreakpoint(props, "medium"),
        themeBreakpoint(props, "xlarge")
      )};
  }

  ${Body}, ${Heading} {
    ${(props) =>
      props.layout == "horizontal"
        ? css`
            max-width: calc(21vw + 108px);
          `
        : css``}
  }
`

interface ConvictionMessages {
  supertitle: string
  title: string
  body: string
}

const defaultMessages: ConvictionMessages = {
  supertitle: "conviction.supertitle",
  title: "conviction.title",
  body: "conviction.body",
}

const ConvictionBlock = ({
  messages = defaultMessages,
}: {
  messages?: ConvictionMessages
  [key: string]: any
}) => {
  const intl = useIntl()
  const layout = useLayoutOrientation({ threshold: "medium" })

  const [progress, updateProgress] = useScrollProgress({
    lowerBound: 0.2,
    upperBound: 0.5,
    active: layout == "horizontal",
  })

  return (
    <Parallax onProgressChange={updateProgress}>
      <Wrapper layout={layout} scrollProgress={progress}>
        <Illustrations.Float
          horizontal="centered"
          vertical="centered"
          horizontalOffset={-160}
          verticalOffset={20 + 30 * progress}
          transf="scale(0.4)"
        >
          <Illustrations.Handshake.Fixed />
          {/* <Illustrations.Accomplish.Fixed /> */}
        </Illustrations.Float>
        <Illustrations.Float
          horizontal="centered"
          vertical="centered"
          horizontalOffset={-140}
          verticalOffset={-10 * (1 - progress) - 50}
        >
          <Illustrations.Bubble.Fixed />
        </Illustrations.Float>

        <Captions>
          <SupTitle small>
            <FormattedMessage id={messages.supertitle} />
          </SupTitle>
          <Heading fluid>
            <HighlightedText
              color="secondary"
              text={intl.formatMessage({ id: messages.title })}
            />
          </Heading>
          <Paragraphs
            text={intl.formatMessage({ id: messages.body })}
            Paragraph={Body}
          />
        </Captions>
      </Wrapper>
    </Parallax>
  )
}

export default ConvictionBlock
