import React, { useState } from "react"
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  useIntl,
} from "gatsby-plugin-intl"
import { useLayoutOrientation } from "../../../../utils/breakpoints"
import Paragraphs from "../../../common/paragraphs"
import Marker from "../../../markers"
import HistoryItem, {
  IHistoryItem,
  IHistoryItemArg,
  timelineSideName,
} from "./item"
import { isOneSided } from "./utils"
import {
  Body,
  Heading,
  Spacer,
  Timeline,
  TimelineCursor,
  Wrapper,
} from "./elements"
import {
  ScrollTrackerState,
  useScrollTracker,
  useScrollListener,
} from "../../../../utils/scrollTracker"
import { parseInt } from "lodash"

interface HistoryMessages {
  title: string
  body: string
}

const defaultMessages: HistoryMessages = {
  title: "history.title",
  body: "history.body",
}

const HistoryBlock = ({
  items,
  messages = defaultMessages,
}: {
  messages?: HistoryMessages
  items: IHistoryItemArg[]
  [key: string]: any
}) => {
  const intl = useIntl()
  const [indicatorVisible, setIndicatorVisible] = useState(false)
  const [currentPosition, setCurrentPosition] = useState(1)
  const layout = useLayoutOrientation({ threshold: "xsmall" })
  const oneSided = isOneSided()

  const tracker = useScrollTracker<HTMLDivElement, HTMLDivElement>({
    trackedNodes: items,
    referenceAnchor: 0.5,
    callback: () => {
      setCurrentPosition(parseInt(tracker.activeNode!))
      if (tracker.state == ScrollTrackerState.within) {
        setIndicatorVisible(true)
      } else if (tracker.state == ScrollTrackerState.before) {
        setIndicatorVisible(false)
      } else {
        setIndicatorVisible(false)
      }
    },
  })
  useScrollListener(() => {
    tracker.didScroll()
  })

  const refItems = tracker.trackedNodes as IHistoryItem[]

  return (
    <Wrapper layout={layout}>
      <Heading>
        <FormattedMessage id={messages.title} />
      </Heading>

      <Paragraphs
        Paragraph={Body}
        text={intl.formatMessage({ id: messages.body })}
      />

      <Timeline
        ref={tracker.container}
        layout={layout}
        className={oneSided ? "one-sided" : ""}
      >
        {refItems.map((item) => (
          <>
            <HistoryItem
              item={item}
              intl={intl}
              index={refItems.indexOf(item) + 1}
              last={refItems.indexOf(item) == refItems.length - 1}
              layout={layout}
              key={`item-${refItems.indexOf(item)}`}
              className={`side-${timelineSideName(refItems, item)}`}
            />
            <Spacer key={`spacer-${refItems.indexOf(item)}`}></Spacer>
          </>
        ))}
        <TimelineCursor className={indicatorVisible ? `visible` : ""}>
          <Marker
            color="primary"
            number={indicatorVisible ? currentPosition : undefined}
          />
        </TimelineCursor>
      </Timeline>
    </Wrapper>
  )
}

export default HistoryBlock
