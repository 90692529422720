import { fluidRange } from "polished"
import React, { useRef } from "react"
import styled from "styled-components"
import styledMap from "styled-map"
import HeadShake from "react-reveal/HeadShake"
import Fade from "react-reveal/Fade"
import { H1, P } from "../../common/typography"
import useBreakpoints, {
  isLowerThanBreakpoint,
  themeBreakpoint,
} from "../../../utils/breakpoints"
import Marker from "../../markers"
import Icons from "../../icons"
import Illustrations from "../../illustrations"
import HighlightedText from "../../common/highlightedText"
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  useIntl,
} from "gatsby-plugin-intl"

const HeroTitle = styled(H1)`
  margin: 0 32vw;
  text-align: center;
  ${(props) =>
    fluidRange(
      [
        { prop: "margin-top", fromSize: "24px", toSize: "40px" },
        { prop: "margin-bottom", fromSize: "24px", toSize: "40px" },
      ],
      themeBreakpoint(props, "small"),
      themeBreakpoint(props, "large")
    )}
`

const HeroSupTitle = styled(P)``

const ScrollButton = styled.div`
  cursor: pointer;
`

const FloatingElement = styled.div<{
  walking1?: boolean
  walking2?: boolean
  walking3?: boolean
  icon1?: boolean
  icon2?: boolean
  icon3?: boolean
  scaleDown?: boolean
  downScale?: number
  hide?: boolean
}>`
  position: absolute;

  left: ${styledMap`
    walking1: 70vw;
    walking2: 85vw;
    walking3: 10vw;
    icon1: 30vw;
    icon2: 35vw;
    icon3: 65vw;
  `};

  top: ${styledMap`
    walking1: 15%;
    walking2: 40%;
    walking3: 30%;
    icon1: 35%;
    icon2: 80%;
    icon3: 95%;
  `};

  ${(props) =>
    props.scaleDown ? `transform: scale(${props.downScale ?? 0.8});` : ""}
  ${(props) => (props.hide == true ? "opacity: 0;" : "")}
`

const HeroWrapper = styled.div<{ breakpoint: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;
`

export interface MissionHeroMessages {
  supertitle: string
  title: string
}

export const defaultMessages: MissionHeroMessages = {
  supertitle: "mission.supertitle",
  title: "mission.title",
}

const MissionHero = ({
  messages = defaultMessages,
}: {
  messages?: MissionHeroMessages
  [key: string]: any
}) => {
  const intl = useIntl()
  const { currentBreakpoint, breakpoints } = useBreakpoints()
  const isLowerThan = isLowerThanBreakpoint(breakpoints, currentBreakpoint)
  const ref = useRef(null)

  return (
    <HeroWrapper ref={ref} breakpoint={currentBreakpoint}>
      <HeroSupTitle small>
        <FormattedMessage id={messages.supertitle} />
      </HeroSupTitle>

      <HeroTitle fluid>
        <HighlightedText
          text={intl.formatMessage({ id: messages.title })}
          color="accent"
        />
      </HeroTitle>

      <Fade delay={500}>
        <HeadShake forever duration={2000} delay={500}>
          <ScrollButton
            onClick={() => {
              if (ref.current == null) return
              const el: HTMLElement = ref.current
              scroll({
                top: el.offsetTop + el.offsetHeight,
                behavior: "smooth",
              })
            }}
          >
            <Marker small color="accent" Icon={Icons.ArrowDown} />
          </ScrollButton>
        </HeadShake>
      </Fade>

      <FloatingElement walking1 scaleDown={isLowerThan("xsmall")}>
        <Illustrations.Float horizontal="rightwards" vertical="centered">
          <Illustrations.Walking_2.Fixed />
        </Illustrations.Float>
      </FloatingElement>

      <FloatingElement walking2 scaleDown={isLowerThan("xsmall")}>
        <Illustrations.Float horizontal="rightwards" vertical="downwards">
          <Illustrations.Walking_1.Fixed />
        </Illustrations.Float>
      </FloatingElement>

      <FloatingElement walking3 scaleDown={isLowerThan("xsmall")}>
        <Illustrations.Float horizontal="centered" vertical="downwards">
          <Illustrations.Walking_3.Fixed />
        </Illustrations.Float>
      </FloatingElement>

      <FloatingElement icon1 downScale={0.8} scaleDown={isLowerThan("large")}>
        <Illustrations.Float horizontal="leftwards" vertical="upwards">
          <Illustrations.Icon_1.Fixed />
        </Illustrations.Float>
      </FloatingElement>

      <FloatingElement
        icon2
        downScale={0.5}
        scaleDown={true}
        hide={isLowerThan("large")}
      >
        <Illustrations.Float horizontal="leftwards" vertical="downwards">
          <Illustrations.Icon_2.Fixed />
        </Illustrations.Float>
      </FloatingElement>

      <FloatingElement
        icon3
        scaleDown={isLowerThan("large")}
        hide={isLowerThan("medium")}
      >
        <Illustrations.Float horizontal="rightwards" vertical="centered">
          <Illustrations.Mail_2.Fixed />
        </Illustrations.Float>
      </FloatingElement>
    </HeroWrapper>
  )
}

export default MissionHero
